import { ModuleTree } from "vuex";

export const UiModule: ModuleTree<unknown> = {
  ui: {
    namespaced: true,
    state: {
      topNavbarOpened: false
    },
    mutations: {
      /**
       * Toggles top navigation bar
       * @param state Vuex state
       */
      toggleTopNavbar(state) {
        state.topNavbarOpened = !state.topNavbarOpened;
      },

      /**
       * Closes top navigation bar
       * @param state Vuex state
       */
      closeTopNavbar(state) {
        state.topNavbarOpened = false;
      }
    }
  }
};
