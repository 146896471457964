import Vue from "vue";

Vue.config.devtools = JSON.parse(process.env.VUE_APP_DEV_TOOLS);

import App from "./App.vue";
import router from "./router";
import store from "./store";

import materialIconsInitializer from "@/initializers/material-icons";
import "@/initializers/brand-icons";
import "@/initializers/smoothscroll-polyfill";

// Initializers
materialIconsInitializer();

// Stylesheets
import "@/stylesheets/global/tailwind.scss";
import "@/stylesheets/global/main.scss";

// Vendor stylesheets
import "@/stylesheets/vendor/swiper-core.scss";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
