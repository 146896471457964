








import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";

@Component({
  components: {
    AppTopNavbar: () =>
      import(
        /* webpackChunkName: 'app-top-navbar' */ "@/components/ui/AppTopNavbar.vue"
      ),
    AppFooter: () =>
      import(
        /* webpackChunkName: 'app-footer' */ "@/components/ui/AppFooter.vue"
      ),
    AppOverlay: () =>
      import(
        /* webpackChunkName: 'app-overlay' */ "@/plugins/overlay/components/AppOverlay.vue"
      )
  }
})
export default class App extends Vue {}
