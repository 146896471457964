/** Available router path names */
export enum RouterPathName {
  HomePage = "HomePage",
  Services = "Services",
  CaseStudies = "CaseStudies",
  Technology = "Technology",
  CaseStudy = "CaseStudy",
  Estimate = "Estimate",
  PrivacyPolicy = "PrivacyPolicy"
}
