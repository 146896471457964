import { technologies } from "@/mock/technologies";

import { Technology } from "@/interfaces/technology";

import { TechnologySlug } from "@/enums/technology-slug.enum";

/** Technology based functionalities class */
export abstract class TechnologyService {
  /**
   * Returns technologies filtered by slug(s)
   * @param slug Slug(s)
   */
  static getTechnologiesWithSlug(...slug: TechnologySlug[]): Technology[] {
    return technologies.filter(tech => slug.includes(tech.slug));
  }
}
