import { Technology } from "@/interfaces/technology";

import { TechnologySlug } from "@/enums/technology-slug.enum";

/** Available technologies */
export const technologies: Technology[] = [
  {
    name: "React.js",
    slug: TechnologySlug.React,
    logoFilename: "react.svg",
    homepageUrl: "https://reactjs.org/"
  },
  {
    name: "Next.js",
    slug: TechnologySlug.Next,
    logoFilename: "next.svg",
    homepageUrl: "https://nextjs.org/"
  },
  {
    name: "Vue.js",
    slug: TechnologySlug.Vue,
    logoFilename: "vue.svg",
    homepageUrl: "https://vuejs.org/"
  },
  {
    name: "Angular",
    slug: TechnologySlug.Angular,
    logoFilename: "angular.svg",
    homepageUrl: "https://angular.io/"
  },
  {
    name: "Symfony",
    slug: TechnologySlug.Symfony,
    logoFilename: "symfony.svg",
    homepageUrl: "https://symfony.com/"
  },
  {
    name: "PHP",
    slug: TechnologySlug.Php,
    logoFilename: "php.svg",
    homepageUrl: "https://php.net/"
  }
];
