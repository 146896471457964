import { NavigationGuard } from "vue-router";

import { technologies } from "@/mock/technologies";

import { RouterPathName } from "@/enums/router-path-name.enum";
import { TechnologySlug } from "@/enums/technology-slug.enum";

/**
 * Parametrized guard which checks if existent technology is present in route
 * @param technologyParamName Param name under which technology name is contained
 */
export const existentTechnologyGuard = (
  technologyParamName: string
): NavigationGuard => {
  return (to, from, next) => {
    const technologiesSlugs = technologies.map(tech => tech.slug);
    if (
      technologiesSlugs.includes(
        to.params[technologyParamName] as TechnologySlug
      )
    ) {
      next();
    } else {
      next({
        name: RouterPathName.HomePage
      });
    }
  };
};
