import FontFaceObserver from "fontfaceobserver";

/** Initializes Material Icons, so they don't mess with elements spacing before load */
const materialIconsInitializer = () => {
  const observer = new FontFaceObserver("Material Icons");
  return observer
    .load("font_download")
    .then(() => document.body.classList.add("site--ui-icons-loaded"));
};

export default materialIconsInitializer;
