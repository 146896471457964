import { NavigationGuard } from "vue-router";

import { projects } from "@/mock/projects";

import { RouterPathName } from "@/enums/router-path-name.enum";

/**
 * Parametrized guard which checks if existent project is present in route
 * @param projectParamName Param name under which project name is contained
 */
export const existentProjectGuard = (
  projectParamName: string
): NavigationGuard => {
  return (to, from, next) => {
    const projectsSlugs = projects.map(project => project.slug);
    if (projectsSlugs.includes(to.params[projectParamName])) {
      next();
    } else {
      next({
        name: RouterPathName.HomePage
      });
    }
  };
};
