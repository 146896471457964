import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import { existentTechnologyGuard } from "@/guards/existent-technology.guard";
import { existentProjectGuard } from "@/guards/existent-project.guard";

import { RouterPathName } from "@/enums/router-path-name.enum";
import { PositionResult } from "vue-router/types/router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    component: () =>
      import(
        /* webpackChunkName: 'bottom-contact-section-view' */ "@/views/BottomContactSectionView.vue"
      ),
    children: [
      {
        path: "",
        name: RouterPathName.HomePage,
        component: () =>
          import(/* webpackChunkName: 'home-page' */ "@/pages/HomePage.vue")
      },
      {
        path: "services",
        name: RouterPathName.Services,
        component: () =>
          import(
            /* webpackChunkName: 'services-page' */ "@/pages/ServicesPage.vue"
          )
      },
      {
        path: "case-studies",
        name: RouterPathName.CaseStudies,
        component: () =>
          import(
            /* webpackChunkName: 'case-studies-page' */ "@/pages/CaseStudiesPage.vue"
          )
      },
      {
        path: "technology/:technologyName",
        name: RouterPathName.Technology,
        beforeEnter: existentTechnologyGuard("technologyName"),
        component: () =>
          import(
            /* webpackChunkName: 'technology-page' */ "@/pages/TechnologyPage.vue"
          )
      },
      {
        path: "case-study/:projectName",
        name: RouterPathName.CaseStudy,
        beforeEnter: existentProjectGuard("projectName"),
        component: () =>
          import(
            /* webpackChunkName: 'project-page' */ "@/pages/ProjectPage.vue"
          )
      },
      {
        path: "privacy-policy",
        name: RouterPathName.PrivacyPolicy,
        component: () =>
          import(
            /* webpackChunkName: 'privacy-policy-page' */ "@/pages/PrivacyPolicyPage.vue"
          )
      }
    ]
  },
  {
    path: "/estimate",
    component: () =>
      import(/* webpackChunkName: 'default-view' */ "@/views/DefaultView.vue"),
    children: [
      {
        path: "",
        name: RouterPathName.Estimate,
        component: () =>
          import(
            /* webpackChunkName: 'estimate' */ "@/pages/EstimationPage.vue"
          )
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  linkActiveClass: "app__link--active",
  linkExactActiveClass: "app__link--exact-active",
  routes,
  scrollBehavior(): PositionResult {
    return {
      x: 0,
      y: -1e3,
      behavior: "smooth"
    };
  }
});

export default router;
