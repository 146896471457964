import Vue from "vue";
import Vuex from "vuex";

import { UiModule } from "@/modules/ui.module";

Vue.use(Vuex);

Vue.config.devtools = JSON.parse(process.env.VUE_APP_DEV_TOOLS);

export default new Vuex.Store({
  modules: {
    ...UiModule
  }
});
