import { Project } from "@/interfaces/project";
import { TechnologyService } from "@/services/technology.service";
import { TechnologySlug } from "@/enums/technology-slug.enum";

import dvMobile from "@/assets/showcase/david-vasco/mobile-100x216.png";
import dvDesktop from "@/assets/showcase/david-vasco/desktop-384x216.png";

import nwMobile from "@/assets/showcase/nicewood/mobile-100x216.png";
import nwDesktop from "@/assets/showcase/nicewood/desktop-384x216.png";

import smMobile from "@/assets/showcase/stalmark/mobile-100x216.webp";
import smDesktop from "@/assets/showcase/stalmark/desktop-384x216.webp";

/** Available projects */
export const projects: Project[] = [
  {
    companyName: "David Vasco",
    companyLogoFilename: "david_vasco.svg",
    intro: "E-commerce integration",
    slug: "david-vasco",
    url: "https://davidvasco.com.pl",
    technologies: TechnologyService.getTechnologiesWithSlug(
      TechnologySlug.Vue,
      TechnologySlug.Symfony
    ),
    mobileShowcaseFilename: dvMobile,
    desktopShowcaseFilename: dvDesktop
  },
  {
    companyName: "NICEWOOD Boards",
    companyLogoFilename: "nicewood.png",
    intro: "Fully functional online store",
    slug: "nicewood-boards",
    url: "https://nicewoodboards.com",
    technologies: TechnologyService.getTechnologiesWithSlug(
      TechnologySlug.Angular,
      TechnologySlug.Symfony
    ),
    mobileShowcaseFilename: nwMobile,
    desktopShowcaseFilename: nwDesktop
  },
  {
    companyName: "Stalmark",
    companyLogoFilename: "stalmark.svg",
    intro: "A new, customizable website",
    slug: "stalmark",
    url: "https://stalmark.pl",
    technologies: TechnologyService.getTechnologiesWithSlug(
      TechnologySlug.Next
    ),
    mobileShowcaseFilename: smMobile,
    desktopShowcaseFilename: smDesktop
  }
];
